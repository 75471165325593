import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';
import { isSameDay, set } from 'date-fns';

import { useScheduleDemoContext } from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoContext';
import { useTimeZoneContext } from '@/Framework/TimeZone/TimeZoneContext';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import ReCaptcha from '@/Framework/UI/Molecules/Form/ReCaptcha';
import TimeZoneSelect from '@/Framework/UI/Organisms/FinalForm/Fields/TimeZoneSelect';
import DatePicker, { DatePickerArrowAlign } from '@dealroadshow/uikit/core/components/Picker/DatePicker';
import PhoneInput from '@dealroadshow/uikit/core/components/Input/PhoneInput';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconCalendar from '@dealroadshow/uikit/core/components/Icon/IconCalendar';
import IconClock from '@dealroadshow/uikit/core/components/Icon/IconClock';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import Textarea from '@dealroadshow/uikit/core/components/Textarea';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';

import {
  SCHEDULE_DEMO_FORM_NAME,
  TIME_INTERVAL,
} from './constants';
import {
  WEEKDAY_MONTH_DATE_FORMAT,
  TIME_FORMAT,
} from '@/Framework/DateTime/dateFormats';
import { guessTimeZone, getTimeZoneDisplayNameDate } from '@/Framework/TimeZone/helpers';
import {
  getNowDateRounded,
  getScheduleDemoFormTimeValues,
} from './helpers';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';
import validator from './validator';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';

import styles from './scheduleDemoForm.scss';
import spacesStyles from '@dealroadshow/uikit/core/styles/helpers/spaces.scss';

interface IProps<T> {
  tenant?: string,
  withLabels?: boolean,
  submitButtonClassName?: string,
  buttonTitle?: string | React.ReactNode,
  isSubmitting?: boolean,
  initialValues?: T,
  submitButtonContainerClassName?: string,
  notesPlaceholder?: string,
  supportRecaptcha?: boolean,
  isPublicSubmit?: boolean,
}

const ScheduleDemoForm = <T extends { timeZone?: ITimezone }> (
  {
    tenant,
    initialValues,
    isPublicSubmit = true,
    supportRecaptcha = true,
    notesPlaceholder = 'Please provide additional details if applicable...',
    submitButtonClassName,
    submitButtonContainerClassName,
    withLabels,
    buttonTitle,
    isSubmitting,
  }: IProps<T>,
) => {
  const { submitScheduleDemoForm, isFetching } = useScheduleDemoContext();
  const { timeZoneList, timeZoneOptions } = useTimeZoneContext();

  const onSubmit = useCallback(
    (formData) => submitScheduleDemoForm({ ...formData, tenant }, isPublicSubmit),
    [submitScheduleDemoForm, isPublicSubmit, tenant],
  );
  const validate = useCallback(
    (values) => validator(values, supportRecaptcha),
    [supportRecaptcha],
  );

  const formInitialValues = useMemo(
    () => {
      const guessedTimeZone = guessTimeZone(timeZoneList);
      const initialTimeZone = initialValues?.timeZone || (
        timeZoneList.length
          ? {
            timeZone: guessedTimeZone.timeZone,
            value: guessedTimeZone.id,
            label: getTimeZoneDisplayNameDate(guessedTimeZone),
          }
          : null
      );

      const now = getUnixTimestamp(getNowDateRounded(initialTimeZone?.timeZone));

      return {
        dateAt: now,
        timeAt: now,
        timeZone: initialTimeZone,
        ...initialValues,
      };
    },
    [timeZoneList.length, initialValues],
  );

  const renderFields = useCallback(
    (
      {
        invalid,
        values: {
          dateAt: dateAtFormValue,
          timeZone: timeZoneFormValue,
        },
      },
      { Field },
    ) => {
      const {
        now,
        timeAtFieldMinTimeValue,
      } = getScheduleDemoFormTimeValues({ dateAtFormValue, timeZoneFormValue });

      const filterPassedTime = (time) => {
        const currentDate = utcToZonedDate(timeZoneFormValue?.timeZone);
        const selectedDate = new Date(time);
        if (isSameDay(selectedDate, getDateFromUnixTime(dateAtFormValue))) {
          return currentDate.getTime() <= selectedDate.getTime();
        }

        return dateAtFormValue < selectedDate.getTime();
      };

      return (
        <div className={ styles.scheduleDemoForm }>
          <div className={ styles.halfWidthDesktop }>
            <Field
              name="firstName"
              label={ withLabels ? 'First Name' : undefined }
              placeholder="First Name"
              component={ Input }
              dataTest="scheduleDemoFormFirstNameInput"
            />
          </div>
          <div className={ styles.halfWidthDesktop }>
            <Field
              name="lastName"
              label={ withLabels ? 'Last Name' : undefined }
              placeholder="Last Name"
              component={ Input }
              dataTest="scheduleDemoFormLastNameInput"
            />
          </div>
          <div className={ styles.fullWidth }>
            <Field
              name="companyName"
              label={ withLabels ? 'Company Name' : undefined }
              placeholder="Company Name"
              component={ Input }
              dataTest="scheduleDemoFormCompanyNameInput"
            />
          </div>
          <div className={ styles.halfWidthDesktop }>
            <Field
              name="corporateEmail"
              label={ withLabels ? 'Corporate Email' : undefined }
              placeholder="Corporate Email"
              icon={ IconEmail }
              component={ Input }
              dataTest="scheduleDemoFormCorporateEmailInput"
            />
          </div>
          <div className={ styles.halfWidthDesktop }>
            <Field
              isFinalForm
              label={ withLabels ? 'Phone' : undefined }
              name="phone"
              placeholder="Phone"
              component={ PhoneInput }
              dataTest="scheduleDemoFormPhoneInput"
            />
          </div>
          <div className={ cn(styles.halfWidthDesktop, spacesStyles.mbxl) }>
            <div className={ styles.halfWidth }>
              <div data-test="dateAt">
                <Field name="dateAt">
                  {
                    ({ meta, input }) => (
                      <DatePicker
                        minDate={ now }
                        dateFormat={ WEEKDAY_MONTH_DATE_FORMAT }
                        selected={ getDateFromUnixTime(input.value) }
                        onChange={ (value: Date) => input.onChange(getUnixTimestamp(value)) }
                        placeholderText="Select Date"
                        dataTest="scheduleDemoFormAtDatePicker"
                        customInput={ (
                          <Input
                            label={ withLabels ? 'Date' : undefined }
                            name="dateAt"
                            // @ts-ignore
                            input={ { name: input.name } }
                            meta={ meta }
                            icon={ IconCalendar }
                            iconPosition="right"
                            isClearable={ false }
                            isReadOnly
                            isNarrow
                            dataTest="scheduleDemoFormDateAtInput"
                          />
                        ) }
                      />
                    )
                  }
                </Field>
              </div>
            </div>
            <div className={ styles.halfWidth }>
              <div data-test="timeAt">
                <Field name="timeAt">
                  {
                    ({ meta, input }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        className={ styles.timeAtSelect }
                        timeIntervals={ TIME_INTERVAL }
                        dateFormat={ TIME_FORMAT }
                        selected={ getDateFromUnixTime(input.value) }
                        onChange={ (value: Date) => input.onChange(getUnixTimestamp(value)) }
                        filterTime={ filterPassedTime }
                        minTime={ timeAtFieldMinTimeValue }
                        maxTime={ set(new Date(), { hours: 23, minutes: 46 }) }
                        popperPlacement="bottom"
                        arrowAlign={ DatePickerArrowAlign.Center }
                        dataTest="scheduleDemoFormTimeAtDatePicker"
                        customInput={ (
                          <Input
                            // @ts-ignore
                            input={ { name: input.name } }
                            label={ withLabels ? 'Time' : undefined }
                            meta={ meta }
                            icon={ IconClock }
                            iconPosition="right"
                            isClearable={ false }
                            isReadOnly
                            isNarrow
                            dataTest="scheduleDemoFormTimeAtInput"
                          />
                        ) }
                      />
                    )
                  }
                </Field>
              </div>
            </div>
          </div>
          <div className={ cn(styles.halfWidthDesktop) }>
            <Field
              name="timeZone"
              label={ withLabels ? 'Timezone' : undefined }
              options={ timeZoneOptions }
              component={ TimeZoneSelect }
            />
          </div>
          <div className={ cn(styles.fullWidth, styles.detailTextareaWrp) }>
            <Field
              name="notes"
              placeholder={ notesPlaceholder }
              rows={ 5 }
              component={ Textarea }
              dataTest="scheduleDemoFormNotesTextarea"
            />
          </div>
          { supportRecaptcha && (
            <div className={ cn(styles.fullWidth, styles.recaptchaWrp) }>
              <Field
                name="recaptchaToken"
                component={ ReCaptcha }
              />
            </div>
          ) }
          <div className={ cn(styles.submitBtnContainer, submitButtonContainerClassName) }>
            <Button
              type="submit"
              variant={ ButtonVariantType.action }
              className={ submitButtonClassName }
              disabled={ invalid }
              // @ts-ignore
              title={ buttonTitle || 'Submit Request' }
              dataTest="scheduleDemoSubmitButton"
            />
          </div>
        </div>
      );
    },
    [timeZoneOptions.length, isSubmitting],
  );

  if (isFetching) {
    return (
      <Spinner />
    );
  }

  return (
    <FinalForm
      name={ SCHEDULE_DEMO_FORM_NAME }
      dataTest={ SCHEDULE_DEMO_FORM_NAME }
      validate={ validate }
      onSubmit={ onSubmit }
      initialValues={ formInitialValues }
      render={ renderFields }
    />
  );
};

export default ScheduleDemoForm;
